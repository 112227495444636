import { upsertForm } from './form'
// import { actionCodeSettings } from '../firebase/firebase'
import {
    getAuth, signOut, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode,
    signInAnonymously, signInWithCustomToken, confirmPasswordReset, updatePassword, reauthenticateWithCredential, EmailAuthProvider,
    GoogleAuthProvider, linkWithPopup, OAuthProvider
} from 'firebase/auth'
import { formStates } from '../constants/helper-states'
import { setActiveUserToken } from './ui'

import Cookies from 'universal-cookie'


const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

const killPartialState = (key) => {
    return {
        type: `KILL_PARTIAL_${key}`
    }
}

const saveAuth = (auth) => {
    return {
        type: `SAVE_AUTH`,
        auth
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: `SAVE_AUTH_HANDLER`,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: 'AUTH_HANDLER_ERROR'
    }
}

export const clearAuthCode = () => {
    return {
        type: `REMOVE_AUTH_HANDLER`
    }
}

export const connectToPusherWebsocket = (fireauth) => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            return {
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            console.log('unable to get authorization info')
        })
}

export const handleFirebaseAuthCode = (auth, code, action) => {
    return dispatch => {
        verifyPasswordResetCode(auth, code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = (fireauth) => {
    return dispatch => {
        signInAnonymously(fireauth)
            .then(() => {
                // dispatch(upsertFetching(fetchingId, fetchingStates.COMPLETE))
            })
            .catch(err => {
                console.log('error with anonymous')
                console.log(err)
            })
    }
}

// export const registerWithInvitation = (model, password, invitationId, formId) => {
//     return async dispatch => {
//         const result = await model.acceptInvitation({ password, invitationId })
//         if (typeof result === 'undefined' || result.hasError) {
//             dispatch(upsertForm(formId, formStates.ERROR, `Error: ${result.error.message}`))
//         } else {
//             fireauth.signInWithEmailAndPassword(model.get('email'), password)
//                 .then(() => {
//                     dispatch(upsertForm(formId, formStates.SUCCESS))
//                 })
//                 .catch(err => {
//                     console.log(err)
//                     dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
//                 })
//         }
//     }
// }

export const signInWithUserToken = (fireauth, token) => {
    return dispatch => {
        const cookies = new Cookies()
        cookies.set('user-token-active', true, { path: '/' })
        dispatch(setActiveUserToken())
        // dispatch partial kill state
        const killKeys = ['ACCOUNT_TYPE', 'ANSWERS', 'ENGAGEMENTS', 'ENGAGEMENT_DETAILS', 'ENGAGEMENT_FILES',
            'ENGAGEMENT_SIGNATURES', 'INVITATIONS', 'MASTER_STANDARD_FIELDS', 'NDAS', 'OPTIONS', 'PERMISSIONS',
            'PROVISIONS', 'PUBLIC_ROLES', 'SECTIONS', 'STANDARDS', 'STANDARD_FILES', 'STANDARD_STATUSES', 'TEAMS']
        killKeys.forEach(key => {
            dispatch(killPartialState(key))
        })
        signInWithCustomToken(fireauth, token)
            .then(() => {
                console.log('signed in with custom token')
            })
            .catch(err => {
                console.log('unable to switch auth token?')
                dispatch(killState())
            })
    }
}

export const registerInvitationWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                // TODO: do we want to handle specific response codes?
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const signInWithGoogle = (fireauth) => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(fireauth, provider).then((result) => {
    }).catch((error) => {
        console.log('Error duing authentication with google')
    })
}

export const signInWithOkta = (fireauth) => {
    const provider = new OAuthProvider('oidc.okta')
    provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: 'user@example.com'
    })

    signInWithPopup(fireauth, provider).then((result) => {
    }).catch((error) => {
        console.log('Error duing authentication with google')
    })
}

export const handleAccountLinkingWithGoogle = async () => {
    const auth = getAuth()
    const googleProvider = new GoogleAuthProvider()

    await linkWithPopup(auth.currentUser, googleProvider).then((result) => {
    }).catch((err) => {
        console.log('unable to link account with google provider', err)
    })
}

export const generatePasswordResetLink = (fireauth, email, formId) => {
    return dispatch => {
        sendPasswordResetEmail(fireauth, email)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const resetPasswordWithCode = (auth, code, email, password, formId) => {
    return dispatch => {
        return confirmPasswordReset(auth, code, password)
            .then((response) => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?')
                    })
            })
            .catch(err => {
                console.log('in here?')
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.newPassword !== formData.confirmPassword) {
            dispatch(upsertForm(formId, formStates.ERROR, 'New passwords do not match.'))
            return false
        }
        const auth = getAuth()
        const fireUser = auth.currentUser
        const credentials = EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
        return reauthenticateWithCredential(fireUser, credentials)
            .then(() => {
                updatePassword(fireUser, formData.newPassword)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                    })
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

// export const handleAnonymousFirebaseAuth = (firebaseUser) => {
//     return dispatch => {
//         dispatch(saveAnonymousAuth(firebaseUser))
//     }
// }

export const handleSignOut = () => {
    return dispatch => {
        const cookies = new Cookies()
        cookies.remove('user-token-active', { path: '/' })
        const auth = getAuth()
        signOut(auth)
            .then(() => {
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
