import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'

import { routes } from '../constants/routes'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, removeAuth, saveAnonymousAuth } from '../actions/auth'
import { setActiveUserToken } from '../actions/ui'

import AuthRouter from './auth/router'

import PageLoader from './pages/loader'
import ApiLoader from './api-loader/api-loader'
import AuthTokenBand from './api-loader/auth-token-band'
import DevTools from './utils/dev-tools'
import { AppRoute, AdminRoute } from './utils/protected-routes'

// Font Awesome pre-loader
import './utils/icons'
import Cookies from 'universal-cookie'

// import '../assets/scss/app.scss'

const AdminIndex = lazy(() => import('./admin/index'))
const AppIndex = lazy(() => import('./app/index'))

const SignaturesIndex = lazy(() => import('./signatures/index'))
const CounterPartyIndex = lazy(() => import('./counter-party/index'))
const ClientSignaturesIndex = lazy(() => import('./client-signatures/index'))
const MarketingIndex = lazy(() => import('./marketing/index'))
const OutboundIndex = lazy(() => import('./outbound/index'))

//  check optimus - add instance of auth

const cookies = new Cookies()

const ROUTER_PAGES = [routes.authLogin.path, routes.router.path, routes.authRegister.path, routes.authPasswordReset.path]
class App extends React.Component {
    state = {
        hasAuthRouted: false,
        fireauth: null,
        shouldRoute: false
    }

    triggerAuthRouter = () => {
        const fromState = this.props.location.state && this.props.location.state.from.pathname
            ? this.props.location.state.from.pathname
            : null
        return <Navigate to={routes.router.path} state={{ from: { pathname: fromState } }} />
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        const fireauth = getAuth(this.props.firebaseApp)
        this.setState({ fireauth })
        onAuthStateChanged(fireauth, firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(saveAnonymousAuth(firebaseUser))
                } else {
                    dispatch(handleFirebaseAuth(firebaseUser))
                    const pathname = this.props.location.pathname
                    if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                        pathname.indexOf('/app/') !== -1 ||
                        pathname.indexOf('/admin/') !== -1 ||
                        pathname.indexOf('/invitation/') === 0
                    ) {
                        this.setState({ hasAuthRouted: true })
                        this.setState({ shouldRoute: true })
                    }
                }
            } else {
                dispatch(removeAuth())
                this.setState({ hasAuthRouted: false })
            }
        })
        if (cookies.get('user-token-active') && !this.props.ui.get('userTokenActive')) {
            dispatch(setActiveUserToken())
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.hasAuthRouted && !this.props.auth.isEmpty()) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                const pathname = this.props.location.pathname
                if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                    pathname.indexOf('/app/') !== -1 ||
                    pathname.indexOf('/admin/') !== -1 ||
                    pathname.indexOf('/invitation/') === 0
                ) {
                    this.setState({ hasAuthRouted: true })
                    this.setState({ shouldRoute: true })
                }
            }
        }
        if (this.state.hasAuthRouted && this.state.shouldRoute) {
            this.setState({ shouldRoute: false })
        }
    }

    render = () => {
        const { auth, role } = this.props

        if (this.state.shouldRoute) {
            return this.triggerAuthRouter()
        }

        return (
            <Suspense fallback={<PageLoader />}>
                <ApiLoader />
                <AuthTokenBand />
                <Routes>
                    {/* // Admin scss, loads app scss */}
                    <Route path={`${routes.adminIndex.path}*`} element={<AdminRoute auth={auth} role={role} location={this.props.location}><AdminIndex fireauth={this.state.fireauth} /></AdminRoute>} />
                    <Route path={routes.router.path} element={<AppRoute auth={auth} location={this.props.location}><AuthRouter /></AppRoute>} />
                    <Route path={`${routes.appIndex.path}*`} element={<AppRoute auth={auth} location={this.props.location}><AppIndex fireauth={this.state.fireauth} /></AppRoute>} />
                    {/* // own scss, might need some */}
                    <Route path={routes.signaturesIndex.path} element={<SignaturesIndex />} />
                    <Route path={routes.clientSignatureIndex.path} element={<ClientSignaturesIndex fireauth={this.state.fireauth} />} />
                    <Route path={routes.counterPartyIndex.path} element={<CounterPartyIndex fireauth={this.state.fireauth} />} />
                    {/* // own scss */}
                    <Route path={routes.publicOutboundIndex.path} element={<OutboundIndex fireauth={this.state.fireauth} />} />
                    {/* //marketing scss */}
                    <Route path={`${routes.index.path}*`} element={<MarketingIndex fireauth={this.state.fireauth} />} />
                </Routes>
                <DevTools />
            </Suspense>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth'),
        role: state.get('role'),
        ui: state.get('ui')
    }
}

export default withRouterProps(connect(mapStateToProps)(App))
