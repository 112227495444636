import React from 'react'
import { handleSignOut } from '../../actions/auth'
import { connect } from 'react-redux'

function Error({ errors, dispatch }) {
    if (!errors) {
        return <></>
    }

    if (Array.isArray(errors) && errors.length > 0) {
        return (
            <>
                <h1 className="m-5 text-center">Error</h1>
                {errors.map((err, index) => <p key={index}>{err.error_message}</p>)}
                <p className="m-5 text-center">
                    <span className="btn blue mr-0" onClick={() => dispatch(handleSignOut())}>Back to Login</span>
                </p>
            </>
        )
    }
}

export default connect()(Error)
